import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/server-side-rendering-with-suspense-in-react-18",
  "date": "2021-10-22",
  "title": "Server Side Rendering with Suspense in React 18",
  "author": "admin",
  "tags": ["development", "javascript", "react", "ssr"],
  "featuredImage": "feature.jpg",
  "excerpt": "React 18 will include architectural improvements to React server-side rendering (SSR) performance."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Server-side rendering is changing in React 18. React 18 will include architectural improvements to React server-side rendering (SSR) performance.
This is supposed to solve the following issues we are currently having today:`}</p>
    <p>{`React 18 will include architectural improvements to React server-side rendering (SSR) performance. This suppose to solve follwing issues we are currently having today:`}</p>
    <ol>
      <li parentName="ol">{`Fetch all data before rendering`}</li>
      <li parentName="ol">{`Load every JS for every component before hydrating HTML `}</li>
      <li parentName="ol">{`Hydrate everything before interacting`}</li>
    </ol>
    <p>{`Current implmentaions of server-side rendering, Next.js for example are having to wait for the API calls to be resolved before rendering HTML and sent to the client. This slows down the intial page load for the client-side.`}</p>
    <h2>{`API Changes`}</h2>
    <p>{`Previously, React did not support Suspense on the server at all. This is changing in React 18`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`renderToString`}</inlineCode>{`: Keeps working (with limited Suspense support).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`renderToNodeStream`}</inlineCode>{`: Deprecated (with full Suspense support, but without streaming).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`pipeToNodeWritable`}</inlineCode>{`: `}<strong parentName="li">{`New and recommended`}</strong>{` (with `}<a parentName="li" {...{
          "href": "https://github.com/reactwg/react-18/discussions/37"
        }}>{`full Suspense support and streaming`}</a>{`).`}</li>
    </ul>
    <p>{`Let's look at these APIs individually. `}</p>
    <p>{`Let's look at these APIs individually. `}</p>
    <h3>{`renderToString`}</h3>
    <p>{`Render a React element to its initial HTML. React will return an HTML string. You can use this method to generate HTML on the server and send the markup down on the initial request for faster page loads and to allow search engines to crawl your pages for SEO purposes. This is synchronous and can't wait any asynchronous processes.`}</p>
    <p><strong parentName="p">{`React 18`}</strong>{`: Limited `}<inlineCode parentName="p">{`<Suspense>`}</inlineCode>{` support`}</p>
    <p>{`The nearest Suspense boundary will be marked as "client-rendered" and immediately emit the fallback HTML in server-side.  `}</p>
    <p>{`On the client however, after the the JavaScript has loaded, React  will retry rendering its content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React from 'react';
import { renderToString } from 'react-dom/server';

function App() {
  const html = renderToString(<>Hey there!</>);
  
  return <div dangerouslySetInnerHTML={{__html: html}}></div>;
};

export default App;
`}</code></pre>
    <h3>{`renderToNodeStream`}</h3>
    <p>{`This function works as same as renderToString() above. The difference  is `}<em parentName="p">{`renderToNodeStream`}</em>{` will have renderer extended from `}<em parentName="p">{`stream.Readable`}</em>{` and it produces an HTML `}<em parentName="p">{`stream`}</em>{` instead of a `}<em parentName="p">{`string.`}</em></p>
    <p>{`This means that you can send the rendered HTML to the client byte-by-byte during rendering, contrary to the standard renderToString, when you have to wait for the whole HTML string to be rendered first, and only after can you send it to the client.`}</p>
    <p><strong parentName="p">{`React 18`}</strong>{`: This will be deprecated in React 18 and will warn you if you use it. The new API `}<inlineCode parentName="p">{`pipeToNodeWritable`}</inlineCode>{` is recommended instead.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import ReactDOMServer from 'react-dom/server';
import express from 'express';

const app = express();

app.use('/static', express.static('public'));

const App = (props) => {
    return <div>Hello {props.name}</div>;
};

const Html = (props) => {
    return (
        <html>
            <body>
                <div id="app">{props.children}</div>
                <script id="initial-data" type="text/plain" data-json={props.initialData}></script>
                <script src="/static/bundle.js"></script>
            </body>
        </html>
    );
};

const initialData = {
    name: 'World'
};

app.get('/', (req, res) => {
    ReactDOMServer.renderToNodeStream(
        <Html initialData={JSON.stringify(initialData)}>
            <App {...initialData} name="World!" />
        </Html>
    ).pipe(res);
});

app.listen(8080, () => {
    console.log('listening on port 8080...');
});
`}</code></pre>
    <h3>{`pipeToNodeWritable`}</h3>
    <p>{`This will be the recommended API going forward. It has all the new features:`}</p>
    <ul>
      <li parentName="ul">{`Full built-in support for `}<inlineCode parentName="li">{`<Suspense>`}</inlineCode>{` (which integrates with data fetching)`}</li>
      <li parentName="ul">{`Code splitting with `}<inlineCode parentName="li">{`lazy`}</inlineCode>{` without flashes of "disappearing" content`}</li>
      <li parentName="ul">{`Streaming of HTML with "delayed" content blocks "popping in" later`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { pipeToNodeWritable } from 'react-dom/server';
`}</code></pre>
    <p>{`Here's a CodeSandBox example from the React team:`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/festive-star-9hfqt?from-embed"
      }}>{`pipeToNodeWritable`}</a></p>
    <h2>{`Advantages using SSR with Suspense`}</h2>
    <p>{`First let's look at the advantages of SSR iteself:`}</p>
    <ul>
      <li parentName="ul">{`Bundle size - Reduce bundle size by moving static, render-only components into the server-side and keep the interactive-stateful components on the client-side`}</li>
      <li parentName="ul">{`Server components can access server-side resources. You can fetch data from the database or the filesystem directly, and you can also fetch from APIs just like on the client-side`}</li>
      <li parentName="ul">{`Server components can read REST APIs as well as GraphQL queries asynchronously`}</li>
    </ul>
    <p>{`By updating to React 18, we also get the following advantages:`}</p>
    <ul>
      <li parentName="ul">{`Streaming HTML`}</li>
      <li parentName="ul">{`SSR with Suspense`}</li>
      <li parentName="ul">{`Selective hydration`}</li>
    </ul>
    <h3>{`Streaming HTML`}</h3>
    <p>{`Stream parts of an HTML page and increase the page load time without having to wait for the whole tree to be loaded before streaming the HTML.`}</p>
    <p>{`To opt into it, you’ll need to switch from renderToString to the new `}<inlineCode parentName="p">{`pipeToNodeWritable`}</inlineCode>{` method.`}</p>
    <h3>{`Server-side Suspense`}</h3>
    <p>{`By wrapping a component with Suspense, React will asynchonously render the component displaying the `}<inlineCode parentName="p">{`fallback`}</inlineCode>{` component and later replace it with the component and hydrate it. This is done by sending the remaining code via a chunk rendered with a `}<inlineCode parentName="p">{`script`}</inlineCode>{` tag`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Suspense fallback={<Loading />}>
   <MyComponent />
</Suspense>
`}</code></pre>
    <h3>{`Selective hydration`}</h3>
    <p>{`Before React 18, everything needed to be loaded before the hydrate step happens. Then only will React hydrate the HMTL before making it interactive (handling events etc).`}</p>
    <p>{`By using `}<inlineCode parentName="p">{`Suspense`}</inlineCode>{` to wrap your components, other parts of the page will be available for interaction. `}</p>
    <p>{`By interacting with a hydrating component, for example clicking on a component that is still loading, React can prioritise the area. React does this by recording that interaction and replaying it once the component has finished hydrating.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      